import Liter from './ImageLogo/LiterSingle.png';
import Playstore from './ImageLogo/playstore.png';

const features = [
  { name: 'GOAL', description: 'Digitisation of the farmer through the dairy industry' },
  { name: 'LANGUAGES', description: '9 languages includes, English, हिंदी, ਪੰਜਾਬੀ, ગુજરાતી, मराठी, বাংলা, ଓଡିଆ, ಕನ್ನಡ, తెలుగు' },
  { name: 'What Matters ?', description: 'Dizitalization of farmer to get more benifits and widely accesible tools' },
  { img: '' },
]




export default function Portfolio() {

  return (
    <>
      <h2 className="text-3xl font-bold  tracking-tight text-red-600 sm:text-4xl  text-center">Our Products</h2>




      <div className=" p-4 mx-5 text-center bg-white text-black sm:p-4 dark:bg-white ">
        <div className="flex justify-center pt-5 items-center">
        <figure className=" ">
  <img className="h-2/4 max-w-2/4  sm:w-2/4 w-2/4 mx-auto sm:mx-auto rounded-lg" src={Liter} alt="image description"/>
 
</figure>
          </div>
        <h5 className="mb-2 text-3xl font-bold "></h5>
        <div className="p-2 leading-normal text-gray-900 dark:text-black"><p>Manage your milk business effortlessly with Liter, offering separate solutions for both farmers and dairy owners.
          <p></p>Simplify record-keeping,Gain insights, Centralized customer management, Streamlined operations</p> Download now or Visit
          <a href="https://app.liter.live/" className="hover:text-blue-700 text-green-800" target="blank"> app.liter.live</a></div>
        <div className="items-center justify-center p-4 pb-0 space-y-4 sm:flex sm:space-y-0  sm:items-center sm:justify-centersm:space-x-4 rtl:space-x-reverse">
         
       
<a href="https://play.google.com/store/apps/details?id=in.liter.live&pcampaignid=web_share" target='_blank' className="max-w-lg">
  <img className="h-2/4 max-w-2/4 sm:w-2/4  sm:mx-auto rounded-lg" src={Playstore} alt="image description"/>
 
</a>

          {/* <a href="https://play.google.com/store/apps/details?id=in.liter.live&referrer=utm_source%3Dfacebook%26utm_medium%3Dhero_banner%26utm_campaign%3DApp%2520Download%2520From%2520Facebook%2520Ads" target="_blank" 
          className="w-auto sm:w-auto bg-black hover:bg-blue-700 focus:ring-4 focus:outline-none p-4 focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-black dark:hover:bg-blue-700 ">
            <svg className="me-3 w-7 h-7" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google-play" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"></path></svg>
    
          </a> */}
        </div>
      </div>

    </>
  );
}
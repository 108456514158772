import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Twitter from './ImageLogo/TwitterIcon.png';
import Close from './ImageLogo/closeIcon.png';
import Facebook from './ImageLogo/facebookIcon.png';
import Instagram from './ImageLogo/instagramIcon.png';

export default function Footer() {
  let [isOpen, setIsOpen] = useState(false)
  let [isOpen1, setIsOpen1] = useState(false)
  let [isOpen2, setIsOpen2] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }
  function closeModal2() {
    setIsOpen1(false)
  }

  function openModal1() {
    setIsOpen1(true)
  }
  function closeModal3() {
    setIsOpen2(false)
  }

  function openModal2() {
    setIsOpen2(true)
  }

  const handleHomeButtonClick = () => {

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <>
    <footer className="m-auto">

    </footer >
<footer className="bg-white dark:bg-gwhite">
    <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-center">
          <div className="mb-6 md:mb-0 -ml-12">
              <a  className="flex justify-center">
                  <img src="https://img.freepik.com/free-vector/contact-us-concept-illustration_114360-2299.jpg?w=740&t=st=1708517257~exp=1708517857~hmac=e08f28288384a59bf6a71c430863ce120b63133633a7cb48c8044ea871310d7es" alt="Photo by ThisIsEngineering: https://www.pexels.com/photo/code-projected-over-woman-3861969/"
                    className="h-80 mx-10"  />
                 
              </a>
          </div>
          <div className=" -ml-40 grid grid-cols-3 gap-8 sm:gap-6 sm:grid-cols-4 sm:ml-10">
              <div>
                  <h2 className="mb-6 text-xl font-bold text-black-900 uppercase dark:black-900">Services</h2>
                  <ul className="text-black-900 dark:text-black-900 font-sm">
                      <li className="mb-2">
                          <a  className="hover:text-red-600 ">PHP</a>
                      </li>
                      <li className="mb-2">
                          <a  className="hover:text-red-600 ">Java</a>
                      </li>
                      <li className="mb-2">
                          <a className="hover:text-red-600 ">React JS</a>
                      </li>
                      <li className="mb-2">
                          <a className="hover:text-red-600 ">Drupal</a>
                      </li>
                      <li className="mb-2">
                          <a  className="hover:text-red-600 ">Graph QL</a>
                      </li>
                      <li className="mb-2">
                          <a className="hover:text-red-600 ">Spring Boot</a>
                      </li>
                      <li className="mb-2">
                          <a className="hover:text-red-600">E-Commerce</a>
                      </li>
                      <li>
                          <a className="hover:text-red-600">End-to-End Solutions</a>
                      </li>
                      
                                    </ul>
              </div>

 {/* Terms and Condition Dialogue */}
 <Transition appear show={isOpen} as={Fragment}>

<Dialog as="div" className="relative z-40 " onClose={closeModal}>
  <Transition.Child
    as={Fragment}
    enter="ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >

    <div className="fixed inset-0 bg-black/25" />
  </Transition.Child>
  <div className="fixed inset-0 overflow-y-auto ">
    <div className="flex min-h-full items-center justify-center  p-4 text-center">
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >

        <Dialog.Panel className="w-full sm:w-3/4 md:w-3/4 max-w transform overflow-hidden  rounded-2xl bg-white p-5 text-left align-middle shadow-xl transition-all">
          <Dialog.Title
            as="h2"
            className="mt-6 text-lg font-medium leading-6 text-gray-900"
          >

          </Dialog.Title>

          <form type="submit">

            <div className="-mt-8 m-3 ">
              <div>

                <div className="flex items-center justify-center z-50">
                  <h1 className="text-center text-xl text-red-600 sticky top-0">Terms and Conditions</h1>
                  <div className="ml-auto">
                    <button onClick={closeModal} type="button" className="h-10 w-10 rounded-md px-3 text-m font-semibold">
                      <img src={Close} alt="Close" />
                    </button>
                  </div>
                </div>
              </div>
              <>
                <p>
                  <b>Use of Services:</b>
                  Loopsys Technologies provides web development services subject to the following conditions. We reserve the right to modify, suspend, or discontinue any part of our services without prior notice.
                </p><p>
                  <b>Intellectual Property:</b>
                  All content on this website, including but not limited to text, graphics, logos, images, and software, is the property of Loopsys Technologies and is protected by copyright and other intellectual property laws.
                </p><p>
                  <b>Client Responsibilities:</b>
                  Clients engaging our web development services are responsible for providing accurate and timely information, including project requirements, feedback, and approvals. Delays caused by incomplete or inaccurate information may affect project timelines.
                </p>
                <p>
                  <b>Payment Terms:</b>
                  Payment terms for web development services will be outlined in the project agreement. Failure to make timely payments may result in project delays or suspension of services.
                </p><p>
                  <b>Confidentiality:</b>
                  Loopsys Technologies agrees to keep confidential all proprietary information provided by the client. Similarly, clients are expected to keep any proprietary information related to Loopsys Technologies' processes, methodologies, and technologies confidential.
                </p><p>
                  <b>Project Timelines:</b>
                  Project timelines provided by Loopsys Technologies are estimates and may be subject to change based on project complexity, scope adjustments, or unforeseen circumstances. We will make reasonable efforts to keep clients informed of any changes to project timelines.
                </p><p>
                  <b>Quality Assurance:</b>
                  Loopsys Technologies is committed to delivering high-quality web development services. Clients are encouraged to thoroughly review and provide feedback during designated project phases. Any defects or issues identified will be addressed promptly.
                </p><p>
                  <b>Third-Party Services:</b>
                  Loopsys Technologies may use third-party services or tools during the web development process. Clients agree to comply with the terms and conditions of these third-party services.
                </p><p>
                  <b>Limitation of Liability:</b>
                  Loopsys Technologies shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use our web development services.
                </p><p>
                  <b>Governing Law:</b>
                  These Terms and Conditions shall be governed by and construed in accordance with the laws of Loopsys Technologies. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts in Loopsys Technologies.
                </p>
                <p> By using our website and services, you acknowledge that you have read, understood, and agreed to these Terms and Conditions. Loopsys Technologies reserves the right to update or modify these terms at any time without prior notice.
                </p>

              </>


              <div className=" m-6 flex items-center justify-center gap-x-6">

                <div className="mt-4 justify-center flex">
                  <button
                    type="button"
                    className="inline-flex justify-end rounded-md   bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-white hover:text-red-600 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    Got it.
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Dialog.Panel>

      </Transition.Child>
    </div>
  </div>

</Dialog>
</Transition>

{/* Contact US Dialogue */}
<Transition appear show={isOpen1} as={Fragment}>
<Dialog as="div" className="relative z-10" onClose={closeModal2}>
  <Transition.Child
    as={Fragment}
    enter="ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div className="fixed inset-0 bg-black/25" />
  </Transition.Child>

  <div className="fixed inset-0 overflow-y-auto">
    <div className="flex min-h-full items-center justify-center p-4 text-center">
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
          <Dialog.Title
            as="h2"
            className="text-lg text-center font-medium leading-6 text-gray-900"
          >
            CONTACT US
          </Dialog.Title>
          <div className="mt-2 text-center">
            <p className="text-m text-black">
            Mail Us :
              <a href='mailto:contact@loopsystech.in' className="text-blue-800" > contact@loopsystech.in</a>
            </p>
          </div>

          <div className="mt-4 flex justify-center">
            <button
              type="button"
              className="inline-flex me-0 rounded-md  bg-red-600 px-4 py-1 text-sm font-medium text-white hover:bg-white hover:text-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={closeModal2}
            >
              Got it.
            </button>
          </div>
        </Dialog.Panel>
      </Transition.Child>
    </div>
  </div>
</Dialog>
</Transition>

{/* Privacy poilicy Dialogue */}
<Transition appear show={isOpen2} as={Fragment}>

<Dialog as="div" className="relative z-40 " onClose={closeModal3}>
  <Transition.Child
    as={Fragment}
    enter="ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >

    <div className="fixed inset-0 bg-black/25" />
  </Transition.Child>
  <div className="fixed inset-0 overflow-y-auto ">
    <div className="flex min-h-full items-center justify-center  p-4 text-center">
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >

        <Dialog.Panel className="w-full sm:w-3/4 md:w-3/4 max-w transform overflow-hidden  rounded-2xl bg-white p-5 text-left align-middle shadow-xl transition-all">
          <Dialog.Title
            as="h2"
            className="mt-6 text-lg font-medium leading-6 text-gray-900"
          >

          </Dialog.Title>

          <form type="submit">

            <div className="-mt-8 m-3 ">
              <div>

                <div className="flex items-center justify-center z-50">
                  <h1 className="text-center text-xl text-red-600 sticky top-0">Privacy Policy</h1>
                  <div className="ml-auto">
                    <button onClick={closeModal3} type="button" className="h-10 w-10 rounded-md px-3 text-m font-semibold">
                      <img src={Close} alt="Close" />
                    </button>
                  </div>
                </div>
              </div>
              <>
              <p className="text-s text-black ">
              <h3><b>Loopsys Technologies Privacy Policy</b>  </h3>

              <p>This Privacy Policy describes how Loopsys Technologies ("we", "us" or "our") collects, uses, and discloses your personal information when you use our website, mobile applications, or other online services (collectively, the "Services").</p>
              <p></p>
              <p> <b>Information We Collect</b></p>
              <li>  Personal Information: This includes information that can be used to identify you, such as your name, email address, phone number, postal address, and IP address.</li>
              <li>Usage Data: This includes information about how you use the Services, such as the pages you visit, the time you spend on each page, and the links you click.</li>
              <li>Device Information: This includes information about the device you use to access the Services, such as the type of device, operating system, browser, and unique device identifiers.</li>

              <p><b>How We Use Your Information</b></p>
              <li>Provide, operate, and maintain the Services;</li>
              <li>Improve the Services;</li>
              <li>Personalize your experience;</li>
              <li>Send you marketing and promotional communications;</li>
              <li>Respond to your inquiries and requests;</li>
              <li>Prevent fraud and protect our users.</li>
              <li>Prevent fraud and protect our users.</li>

              <p><b>Disclosure of Your Information</b></p>
              <p>We may disclose your information to:</p>
              <li>Service providers who help us provide the Services;</li>
              <li>Law enforcement agencies or other government officials, as required by law;</li>
              <li>Third parties with your consent.</li>

              <p> <b>Cookies and Other Tracking Technologies</b></p>
              We use cookies and other tracking technologies to collect and store information about how you use the Services. You can control your cookie preferences through your browser settings.
              <p><b>Data Retention</b></p>
              We will retain your information for as long as your account is active or as needed to provide you with the Services. We will also retain your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
              <p><b>Your Rights</b></p>
              You have the right to access, update, or delete your personal information. You also have the right to opt out of receiving marketing and promotional communications from us.
              <p> <b>Changes to This Privacy Policy</b></p>
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.
              <p>Contact Us</p>
              If you have any questions about this Privacy Policy, please contact us at:
              <b>contact@loopsystech.in</b>

            </p>

              </>


              <div className=" m-6 flex items-center justify-center gap-x-6">

                <div className="mt-4 justify-center flex">
                  <button
                    type="button"
                    className="inline-flex justify-end rounded-md   bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-white hover:text-red-600 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModal3}
                  >
                    Got it.
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Dialog.Panel>

      </Transition.Child>
    </div>
  </div>

</Dialog>
</Transition>

              <div>
                  <h1 className="mb-6 text-xl font-bold text-black-900 uppercase dark:black-900">Company</h1>
                  <ul className="text-black-900 dark:text-black-900 font-medium">
                     
                      <li className="mb-4">
                  <Link to="/About" onClick={handleHomeButtonClick} className="text-blue-700 transition hover:opacity-75 cursor-pointer">
                    About Us</Link>
                </li>

                <li className="mb-4">
                  <a href="https://play.google.com/store/apps/details?id=in.liter.live&pcampaignid=web_share" target="_blank" className="text-blue-700 transition hover:opacity-75 cursor-pointer"> Our App </a>
                </li>
                     
                      <li>
                  <a className="text-blue-700 transition hover:opacity-75 cursor-pointer" onClick={openModal1}> Contact Us </a>
                </li>
                  </ul>
              </div>
              <div>
                  <h1 className="mb-6 text-xl font-bold text-black-900 uppercase dark:text-black-900">Legal</h1>
                  <ul className="text-black-900 dark:text-black-900 font-sm">
                  <li className="mb-4">
                <a className="text-black-900 transition hover:text-red-600 cursor-pointer" onClick={openModal}> Terms and Condition </a>
              </li>

              <li className="mb-4"> 
                <a className="text-black-950 transition cursor-pointer hover:text-red-600" onClick={openModal2}> Privacy Policy </a>
              </li>
                  </ul>
              </div>
          </div>
      </div>
      <hr className="my-2 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-2" />
      <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-red-600 sm:text-center d">© 2023 <a >Loopsys Technologies™</a>. All Rights Reserved.
          </span>
          <div className="flex mt-4 sm:justify-center sm:mt-0">
              <a href="https://www.facebook.com/loopsystech.in/" target="_blank" className="text-blue-600 hover:text-red-600 dark:hover:text-red-600 ms-5">
                  <img src ={Facebook} className="w-6 h-6"/>
                  <span className="sr-only">Facebook page</span>
              </a>
              <a href="https://www.instagram.com/loopsystechnologies/"  target="_blank" className="text-blue-600 hover:text-red-600 dark:hover:text-red-600 ms-5">
                  <img src ={Instagram} className="w-6 h-6"/>
                  <span className="sr-only">Instagram Page</span>
              </a>
              <a href="https://twitter.com/loopsysTech" target="_blank" className="text-blue-600 hover:text-red-600 dark:hover:text-red-600 ms-5">
                  <img src ={Twitter} className="w-6 h-6"/>
                  <span className="sr-only">Twitter page</span>
              </a>
              
          </div>
      </div>
    </div>
</footer>
</>

  );
}
export default function HeroImage() {
    return (
        <header className="my-0">
          
<section class="bg-center bg-cover bg-[url('https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')] bg-gray-700 bg-blend-multiply h-screen flex items-center">
    <div class="px-4 mx-auto max-w-screen-xl text-center">
        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">CARVING the FUTURE sense
            <p>
                with LOOPSYS</p>
        </h1>
        <p class="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48"> In the symphony of progress, LOOPSYS carves the future's melody, harmonizing innovation with foresight.</p>
        <div class="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
            <svg class="left-1/2 transform -translate-x-1/2 animate-bounce w-8 h-8 text-white m-auto  sm:m-auto absolute invisible sm:visible sm:bottom-0 mb-12" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
            </svg>
        </div>
    </div>
</section>


        </header>
    )
}

import React from 'react';
export default function About() {
  console.log('About component rendered');
  return (
    <div id="port">
      <div className="mx-auto my-12 max-w-7xl ">
        <div >
          <h1 className=" text-center m-8 font-bold  text-red-600 text-4xl">About Us</h1>
          <p className="mt-2 text-lg text-center text-justify m-8 my-12 text-black">
          Established in April 2021, Loopsys Technologies stands as a pioneering force in the realm of web development.
           Boasting a dynamic team proficient in ReactJS, JAVA, PHP and more tech stacks. We are dedicated to crafting innovative and
            scalable solutions. Our specialization lies in creating seamless and user-centric digital experiences,
             utilizing a blend of cutting-edge technology and creativity.

Committed to excellence, we approach each project with a unique perspective and a fresh surge 
of energy, aiming to meet the diverse needs of our clients. At the core of our values is a keen focus on client
 satisfaction, and we embark on every endeavor with the dedication to deliver not just solutions, but memorable 
 experiences.

In the world of web development, Loopsys Technologies invites you to explore the future, where innovation seamlessly
 meets precision. Our promise is to be your trusted partner in transforming ideas into reality. With a track record 
 of staying ahead of the curve, we invite you to join us on a journey where Loopsys becomes synonymous with
  groundbreaking web development, setting new standards and exceeding expectations. Let us be the architects
   of your digital success, ensuring that the future of web development is not just embraced but defined.
          </p>
          <h2 className="  m-8 font-bold tracking-tight text-red-600 text-4xl">Our Belief</h2>
        <p className="mt-2 text-lg text-center text-justify m-8 my-12 text-blac">Teamwork, Learning and Transparency.</p>
        </div>
        

      </div>
      

 
    </div>
  )
}

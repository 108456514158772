import { Dialog, Popover } from '@headlessui/react';
import {
  Bars3Icon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from './ImageLogo/Loopsys logo without background.png';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Nav() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const handleHomeButtonClick = () => {
    setMobileMenuOpen(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>    <header className="bg-white  sticky top-0 z-40 shadow-md">
      <nav className="mx-auto p-2 text-2xl flex max-w-7xl items-center justify-between p-1 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link to="/" onClick={handleHomeButtonClick} className="-m-1.5 p-1.5">
            <span className="sr-only">Loopsys Technologies</span>
            <img className="h-16 w-30" src={Logo} alt="" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-10 w-10" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <div className="text-m font-semibold leading-6 text-black-950 hover:text-red-600 ">
            <Link to="/" onClick={handleHomeButtonClick}>
              Home</Link>
          </div>
          <Link to="/About" onClick={handleHomeButtonClick} className="text-m font-semibold leading-6 text-black-950 hover:text-red-600">
            About Us</Link>

          <Link to="/Career" onClick={handleHomeButtonClick} className="text-m font-semibold leading-6 text-black-950 hover:text-red-600">
            Career
          </Link>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="" className="text-sm font-semibold leading-6 text-gray-900">
            <span aria-hidden="true"></span>
          </a>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        {mobileMenuOpen && (
    <div className="fixed inset-0 z-10 bg-black opacity-50" onClick={() => setMobileMenuOpen(false)} />
  )}
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-3/4 overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>

            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-10 w-10" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6 text-center">

                <Link to="/" onClick={handleHomeButtonClick} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-black hover:bg-gray-200 hover:text-red-600">
                  Home</Link>
                <Link to="/About" onClick={handleHomeButtonClick}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-black hover:bg-gray-50 hover:text-red-600"
                >
                  About Us
                </Link>
                <Link to="/Career" onClick={handleHomeButtonClick}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-blck hover:bg-gray-50 hover:text-red-600"
                >
                  Career
                </Link>
              </div>
            
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>

    </>
  )
}

import Man from './ImageLogo/manAvatar.png';
const posts = [
    {
        id: 1,
        title: 'Best App',
        href: '',
        description:
            'Best application for my Dairy and Product Management',
        datetime: '2020-03-16',
        category: { title: 'Marketing', href: '' },
        author: {
            name: 'Suresh Patel',
            role: 'Dairy Owner',
            href: '',
            imageUrl:
                Man,
        },
    },
    {
        id: 1,
        title: 'Easy to Use',
        href: '',
        description:
            'Excellent app for the Dairy owners and milk sellers and it is easy to use',
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        category: { title: 'Marketing', href: '' },
        author: {
            name: 'Vikas Patel',
            role: 'Milk Seller',
            href: '',
            imageUrl:
                Man
            },
    },
    {
        id: 1,
        title: 'Supportive Team ',
        href: '#',
        description:
            'Best app from the Loopsys technologies, I am using this app from 2022, A good service given by the team',
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        category: { title: 'Marketing', href: '' },
        author: {
            name: 'Shubham Choudhary',
            role: 'Dairy owner',
            href: '',
            imageUrl:
        Man      
        },
    },
    // More posts...
]

export default function Customers() {
    return (
        <>
            <div className="bg-white py-12 sm:py-11">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto  lg:mx-0">
                        <h2 className="text-3xl font-bold tracking-tight text-red-600 sm:text-4xl  text-center">From the Clients</h2>
                        <p className="mt-2 text-lg leading-8 text-black-950 text-center">
                        Hear directly from our clients about the value, excellence, and trust they have found in our services. Your success story could be next!
                        </p>
                    </div>
                    <div className="mx-auto mt-0 grid max-w-2xl text-center grid-cols-1 gap-x-8 gap-y-8  pt-10 sm:mt-0 sm:pt-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {posts.map((post) => (
                            <article key={post.id} className="flex max-w-xl flex-col items-start justify-between">
                                <div className="flex items-center  gap-x-4 text-xs">
                                    
                                </div>
                                <div className="group relative">
                                    <h3 className="mt-0 text-lg font-semibold leading-6 text-black-950 ">
                                        <a>
                                            <span className="absolute inset-0" />
                                            {post.title}
                                        </a>
                                    </h3>
                                    <p className="mt-2 line-clamp-3 text-sm text-center leading-6 text-black-950">{post.description}</p>
                                </div>
                                <div className="relative mt-4 flex items-center gap-x-4">
                                    <img src={post.author.imageUrl} alt="" className="h-10 w-10 rounded-full bg-gray-50" />
                                    <div className="text-sm leading-6">
                                        <p className="font-semibold text-gray-900">
                                            <a >
                                                <span className="absolute inset-0" />
                                                {post.author.name}
                                            </a>
                                        </p>
                                        <p className="text-gray-600">{post.author.role}</p>
                                    </div>
                                </div>
                            </article>
                        ))}
                    </div>
                </div>
            </div>

        </>

    );
}

import { Dialog, Transition } from '@headlessui/react';
import { PhotoIcon } from '@heroicons/react/24/solid';
import React, { Fragment, useState } from 'react';
import Close from './ImageLogo/closeIcon.png';
const people = [
    {
        Role: 'Junior Developer',
        Subject: 'Applying for ',
        Requirement: 'ReactJS,PHP',
        imageUrl:
            'https://cdn.vectorstock.com/i/1000x1000/65/04/role-text-circled-icon-vector-7356504.webp',
        Experience: '06-12 Month Experience',

    },
    {
        Role: 'Customer Relationship Executive',
        Subject: 'Applying for ',
        Requirement: 'Must have knowledge of MS office(Excel, Presentation, Word)',
        imageUrl:
            'https://cdn.vectorstock.com/i/1000x1000/65/04/role-text-circled-icon-vector-7356504.webp',
        Experience: 'Fresher/Experienced',

    },

]




export default function Career() {
    let [isOpen, setIsOpen] = useState(false)
    let [isOpen1, setIsOpen1] = useState(false)
    let [appliedRole, setAppliedRole] = useState("");

    function closeModal() {
        setIsOpen(false)
    }

    function openModal(role) {
        setIsOpen(true)
        setAppliedRole(role);
    }
    function closeModal2() {
        setIsOpen1(false)
    }

    function openModal1() {
        setIsOpen1(true)
    }
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file && file.type === 'application/pdf') {
            setSelectedFile(file);
            setError('');
        } else {
            setSelectedFile(null);
            setError('Please select a valid PDF file.');
        }
    }

    const Role = [
        'PHP', 'ReactJS', 'Java', 'NodeJs', 'Customer Relationship Executive (CRE)'
    ];


    return (
        <div className="mx-auto my-12 max-w-7xl ">
            <h1 className=" text-center m-2 font-bold tracking-tight text-red-600 text-4xl">Career</h1>
            <div className=" m-10  text-center">
                <h3 className="text-2xl  font-semibold leading-7 text-black">Why Choose Loopsys</h3>
                <p className="mt-2 text-lg text-center text-justify  my-12 text-black">Join a team that values open communication, team work  collaboration and mutual respect and be a part of  the company dedicated to delivering exceptional solutions.
                    We prioritize the continuous development of our team members, offering opportunities for learning and skill enhancement.
                    Check our current role openings and embark a journey of excellence with us.
                </p>

            </div>
            <div className="m-12">



                <h3 className=" text-center text-2xl font-semibold leading-7 text-black">Open Roles</h3>
                <ul role="list" className="divide-y divide-gray-100 text-center">
                    {people.map((person) => (
                        <li key={person.email} className="flex flex-col sm:flex-row justify-between gap-x-6 py-5">
                            <div className="flex flex-col justify center items-center sm:flex-row min-w-0  gap-x-4">
                                <img className="h-12 w-12 flex-none center rounded-full bg-black mb-2 sm:mb-0" src={person.imageUrl} alt="" />
                                <div className="min-w-0 flex-auto">
                                    <p className="text-m font-semibold leading- text-Black0">{person.Role}</p>
                                    <p className="mt-1 truncate text-sm leading-5 text-black">  {person.Experience}</p>
                                </div>
                            </div>
                            <div className=" flex flex-col items-center sm:flex-col sm:items-end">
                                <p className="text-m leading-6 text-Black mb-2 sm:mb-0">{person.Requirement}</p>
                                {person.Experience ? (
                                    <p className="mt-1 text-m leading-5 text-Black">
                                        <button onClick={() => openModal(person.Role)} className="bg-red-600 hover:bg-blue-700 text-center text-white font-bold py-2 px-4 rounded-full">
                                            Apply Now
                                        </button>
                                    </p>
                                ) : (
                                    <div className="mt-1 flex items-center gap-x-1.5">
                                        <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                                            <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                                        </div>
                                        <p className="text-xs leading-5 text-gray-500">  </p>
                                    </div>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>


            </div>



            <Transition appear show={isOpen} as={Fragment}>
              
                <Dialog as="div" className="relative z-40 " onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto ">
                        <div className="flex min-h-full items-center justify-center  p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                
                                <Dialog.Panel className="w-full sm:w-3/4 md:w-3/4 max-w transform overflow-hidden  rounded-2xl bg-white p-5 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h2"
                                        className="mt-6 text-lg font-medium leading-6 text-gray-900"
                                    >

                                    </Dialog.Title>
                                    
                                    <form type="submit">
                                        
                                        <div className="-mt-8 m-3 ">
                                            <div>

                                                <div className="flex items-center justify-center z-50">
                                                    <h1 className="text-center text-xl text-red-600 sticky top-0">Applying for {appliedRole}</h1>
                                                    <div className="ml-auto">
                                                        <button onClick={closeModal} type="button" className="h-10 w-10 rounded-md px-3 text-m font-semibold">
                                                            <img src={Close} alt="Close" />
                                                        </button>
                                                    </div>
                                                </div>



                                                <span>
                                                    <p className=""> Please submit the form to apply</p></span>
                                                <div >
                                                    <h2 className="text-base font-semibold leading-7 text-gray-900"></h2>
                                                    {/* <p className="mt-1 text-m leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> */}
                                                    <div className="max-h-[calc(100vh-3rem)] overflow-y-auto">
                                                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-1 sm:grid-cols-6 overflow-y-auto">
                                                            <div className="sm:col-span-3">
                                                                <label htmlFor="first-name" className="block text-m font-medium leading-6 text-gray-900">
                                                                    First name
                                                                </label>
                                                                <div className="mt-2">
                                                                    <input
                                                                        required
                                                                        type="text"
                                                                        name="first-name"
                                                                        id="first-name"
                                                                        autoComplete="given-name"
                                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-m sm:leading-6s"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="sm:col-span-3">
                                                                <label htmlFor="last-name" className="block text-m font-medium leading-6 text-gray-900">
                                                                    Last name
                                                                </label>
                                                                <div className="mt-2">
                                                                    <input
                                                                        type="text"
                                                                        required
                                                                        name="last-name"
                                                                        id="last-name"
                                                                        autoComplete="family-name"
                                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-m sm:leading-6"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="sm:col-span-2 sm:col-start-1 required" >
                                                                <label htmlFor="Experience" className="block text-m font-medium leading-6 text-gray-900" required>
                                                                    Experience
                                                                </label>
                                                                <div className="mt-2">
                                                                    <input
                                                                        required
                                                                        type="number"
                                                                        placeholder="In Months"
                                                                        id="Experience"
                                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-m sm:leading-6"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="sm:col-span-2">
                                                                <label htmlFor="salary" type="input" placeholder="Write the heading of the post" className="block text-m font-medium leading-6 text-gray-900">
                                                                    Current Salary
                                                                </label>
                                                                <div className="mt-2">
                                                                    <input
                                                                        type="text"
                                                                        name="Role"
                                                                        id="ApplyFor"
                                                                        placeholder="for ex 6 LPA"
                                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-m sm:leading-6"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="sm:col-span-2">
                                                                <label htmlFor="salary" type="input" placeholder="Write the heading of the post" className="block text-m font-medium leading-6 text-gray-900">
                                                                    Expected Salary
                                                                </label>
                                                                <div className="mt-2">
                                                                    <input
                                                                        type="text"
                                                                        name="Role"
                                                                        id="ApplyFor"
                                                                        placeholder="for ex 10 LPA"
                                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-m sm:leading-6"
                                                                    />
                                                                </div>
                                                            </div>



                                                            <div className="sm:col-span-2 sm:col-start-1">
                                                                <label htmlFor="Email" className="block text-m font-medium leading-6 text-gray-900">
                                                                    Email
                                                                </label>
                                                                <div className="mt-2">
                                                                    <input
                                                                        required
                                                                        type="text"
                                                                        name="Email"
                                                                        id="Email"

                                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-m sm:leading-6"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="sm:col-span-2">
                                                                <label htmlFor="MobileNumber" className="block text-m font-medium leading-6 text-gray-900">
                                                                    Mobile number
                                                                </label>
                                                                <div className="mt-2">
                                                                    <input
                                                                        required
                                                                        type="Number"
                                                                        // value="+91" min="91" max="91"
                                                                        name="MobleNumber"
                                                                        id="MobileNumber"
                                                                        Placeholder="+91"
                                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-m sm:leading-6"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="sm:col-span-2">
                                                                <label htmlFor="Address" className="block text-m font-medium leading-6 text-gray-900">
                                                                    Address
                                                                </label>
                                                                <div className="mt-2">
                                                                    <input
                                                                        type="text"
                                                                        name="Address"
                                                                        id="Address"

                                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-m sm:leading-6"
                                                                    />
                                                                </div>
                                                            </div>





                                                        </div>
                                                    </div>

                                                    <div >


                                                        <div className="col-span-full overflow-y-auto">
                                                            <label htmlFor="about" className="block text-m font-medium leading-6 text-gray-900">
                                                                About
                                                            </label>
                                                            <div className="mt-2">
                                                                <textarea
                                                                    required
                                                                    placeholder="Write a few sentences about yourself"
                                                                    id="about"
                                                                    name="about"
                                                                    rows={5}
                                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-m sm:leading-6"
                                                                    defaultValue={''}
                                                                />
                                                            </div>

                                                        </div>


                                                        <div className="col-span-full">
                                                            <label htmlFor="cover-photo" className="block text-m font-medium leading-6 text-gray-900">
                                                                Updated Resume
                                                            </label>
                                                            <div className="mt-2 h-1/2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                                                <div className="text-center">
                                                                    <PhotoIcon className="mx-auto h-6 w-12 text-gray-300" aria-hidden="true" />
                                                                    <div className="mt-4 flex text-m leading-6 text-gray-600">
                                                                        <label
                                                                            htmlFor="file-upload"
                                                                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                                                        >
                                                                            <span>Upload a resume</span>
                                                                            <input
                                                                                required
                                                                                id="file-upload"
                                                                                name="file-upload"
                                                                                accept="application/pdf"
                                                                                type="file"
                                                                                className="sr-only"
                                                                                onChange={handleFileChange}
                                                                            />
                                                                        </label>
                                                                        <p className="pl-1">or drag and drop, </p> 
                                                                        <p className="pl-1"> Only PDF file is allowed</p>
                                                                    </div>
                                                                    <p className="text-xs leading-5 text-black">{error}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div >
                                                <h2 className="text-base font-semibold leading-7 text-gray-900">  </h2>
                                                <p className="m-auto text-m leading-6 text-gray-600">
                                                    By submitting the form your are consent to our privacy policy.
                                                </p>


                                            </div>

                                            <div className=" m-6 flex items-center justify-center gap-x-6">

                                                <button
                                                    type="submit"
                                                    className="rounded-md bg-red-600 px-3 py-2 text-m font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </Dialog.Panel>

                            </Transition.Child>
                        </div>
                    </div>

                </Dialog>
            </Transition>


        </div>
    )
}
